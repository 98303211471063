/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ISurveyList } from '../_contracts/ISurveyList';
import { SurveyList } from '../_models/SurveyList';
import { IAnnualReportList } from '../_contracts/IAnnualReportList';
import { AnnualReportList } from '../_models/AnnualList';
import { OnGoingAdminItems, OngoingItems } from '../_models/Ongoing-Communications';
import { SurveyInfo } from '../_models/SurveyInfo';
import { SurveyDetails } from '../_models/SurveyDetails';
import { IMissingItems } from '../_contracts/IMissingItems';
import { MissingItems } from '../_models/MissingItems';
import { SurveyPayments } from '../_models/SurveyPayments';
import { SurveyProcessCheckpoints } from '../_models/SurveyProcessCheckpoints';
import { CheckPaymentInstruction } from '../_models/CheckPaymentInstrctn';
import { AdditionalCertificates } from '../_models/AdditionalCertificateOrderForm';
import {
  AdditionalCertificatePayment,
  PostAdditionalCertificatePayment,
} from '../_models/AdditionalCertificatePayment';
import { AnnualMaintenanceFee } from '../_models/AnnualMaintenanceFee';
import { ItemsChecklist } from '../_models/ItemsChecklist';
import { AnnualReportHeader } from '../_models/AnnualReportHeader';

type HttpOptions = {
  headers?: HttpHeaders | { [header: string]: string | string[] };
  observe?: 'body';
  params?: HttpParams | { [param: string]: string | string[] };
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
  body?: {};
};

const getHttpOptionsText = (): HttpOptions => {
  return {
    observe: 'body',
    responseType: 'text' as 'json',
  };
};
@Injectable()
export class SurveyService {
  private baseUrl = '';
  private notify = new Subject<any>();
  private notifyPrint = new Subject<any>();
  private additionalCertificatePrint = new Subject<any>();
  notifyObservable$ = this.notify.asObservable();
  notifyPrintObservable$ = this.notifyPrint.asObservable();
  addtnlCertPrintObservable$ = this.additionalCertificatePrint.asObservable();
  isFormEditable = true;
  isAnnualFormEditable = true;
  surveyDetails: SurveyDetails;
  annualDetails: AnnualReportHeader;

  constructor(private httpClient: HttpClient) {
    this.baseUrl = environment.apiUrl;
  }

  getSurveyList(contactId: string): Observable<ISurveyList[]> {
    const operationName = `/survey/list/${contactId}`;
    const encodeURL = this.baseUrl + operationName;
    return this.httpClient.get(encodeURL).pipe(
      map((res: ISurveyList[]) => {
        return res.map((item: ISurveyList) => new SurveyList(item));
      }),
    );
  }

  getAnnualReportList(contactId: string): Observable<IAnnualReportList[]> {
    const operationName = `/annualreport/list/${contactId}`;
    const encodeURL = this.baseUrl + operationName;
    return this.httpClient.get(encodeURL).pipe(
      map((res: IAnnualReportList[]) => {
        return res.map((item: IAnnualReportList) => new AnnualReportList(item));
      }),
    );
  }

  getMissingItemsList(contactId: string): Observable<IMissingItems[]> {
    const operationName = `/missingItems/${contactId}`;
    const encodeURL = this.baseUrl + operationName;
    return this.httpClient.get(encodeURL).pipe(
      map((res: IMissingItems[]) => {
        return res.map((item: IMissingItems) => new MissingItems(item));
      }),
    );
  }

  getSurveyDetails(surveyId: string, contactId: string): Observable<any> {
    const operationName = `/survey/header/${surveyId}/${contactId}`;
    const encodeURL = this.baseUrl + operationName;
    this.isFormEditable = true;
    return this.httpClient.get<any>(encodeURL).pipe(
      map((res: any) => {
        this.surveyDetails = new SurveyDetails(res);
        this.isFormEditable = !(
          (!this.surveyDetails.IsSubmitter && !this.surveyDetails.IsEditor) ||
          !['Scheduling', 'Survey Pending', 'Survey Report Processing'].includes(
            this.surveyDetails.Status,
          )
        );
        return this.surveyDetails;
      }),
    );
  }

  getAnnualReportHeader(acqrId: string, contactId: string): Observable<any> {
    const operationName = `/annualreport/header/${acqrId}/${contactId}`;
    const encodeURL = this.baseUrl + operationName;
    this.isAnnualFormEditable = true;
    return this.httpClient.get<any>(encodeURL).pipe(
      map((res: any) => {
        this.annualDetails = new AnnualReportHeader(res);
        this.isAnnualFormEditable = !(
          (!this.annualDetails.IsSubmitter && !this.annualDetails.IsEditor) ||
          ['Submitted', 'In Review', 'Complete'].includes(this.annualDetails.Status)
        );
        return this.annualDetails;
      }),
    );
  }

  getSurveyPaymentDetails(surveyId: string): Observable<any> {
    const operationName = `/payment/survey/${surveyId}`;
    const encodeURL = this.baseUrl + operationName;
    return this.httpClient.get<any>(encodeURL).pipe(
      map((res: any) => {
        return new SurveyPayments(res);
      }),
    );
  }

  updateSurveyPayment(updateSurvey): Observable<any> {
    const operationName = `/payment/survey`;
    const encodeURL = this.baseUrl + operationName;
    const reqBody = updateSurvey;
    const HttpOptions = getHttpOptionsText();
    return this.httpClient.put<any>(encodeURL, reqBody, HttpOptions).pipe(
      map((res: any) => {
        return res;
      }),
    );
  }

  getSurveyDocuments(surveyId: string) {
    const operationName = `/survey/documents/${surveyId}`;
    const encodeURL = this.baseUrl + operationName;
    return this.httpClient.get<any>(encodeURL).pipe(
      map((res: any) => {
        return res;
      }),
    );
  }

  downloadSurveyAttachment(item): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      observe: 'response' as 'body',
      responseType: 'blob' as 'json',
    };
    const reqBody = item;
    const operationName = '/survey/attachment';
    const encodeURL = this.baseUrl + operationName;
    return this.httpClient.put<any>(encodeURL, reqBody, options).pipe(map((response) => response));
  }

  getAdditionalCertCheckPaymentIns(surveyCertId): Observable<CheckPaymentInstruction> {
    const operationName = `/additionalcertificates/check/${surveyCertId}`;
    const encodeURL = this.baseUrl + operationName;
    return this.httpClient.get<any>(encodeURL).pipe(
      map((res: any) => {
        return new CheckPaymentInstruction(res);
      }),
    );
  }

  getSurveyInfo(surveyId: string): Observable<SurveyInfo> {
    const operationName = `/survey/surveyinfo/${surveyId}`;
    const encodeURL = this.baseUrl + operationName;
    return this.httpClient.get<any>(encodeURL).pipe(
      map((res: any) => {
        return new SurveyInfo(res);
      }),
    );
  }

  getSurveyProcessCheckpoints(surveyId: string): Observable<SurveyProcessCheckpoints> {
    const operationName = `/survey/checkpoint/${surveyId}`;
    const encodeURL = this.baseUrl + operationName;
    return this.httpClient.get<any>(encodeURL).pipe(
      map((res: any) => {
        return new SurveyProcessCheckpoints(res);
      }),
    );
  }

  getAdditionalCertificates(surveyId: string): Observable<AdditionalCertificates> {
    const operationName = `/additionalcertificates/${surveyId}`;
    const encodeURL = this.baseUrl + operationName;
    return this.httpClient.get<any>(encodeURL).pipe(
      map((res: any) => {
        return new AdditionalCertificates(res);
      }),
    );
  }

  getOCAnnualReport(ACQRId: string): Observable<OnGoingAdminItems> {
    const operationName = `/annualreport/${ACQRId}`;
    const encodeURL = this.baseUrl + operationName;
    return this.httpClient.get<any>(encodeURL).pipe(
      map((res: any) => {
        return new OnGoingAdminItems(res);
      }),
    );
  }

  getOCAnnualReportCerti(ACQRId: string): Observable<OngoingItems> {
    const operationName = `/annualreport/certification/${ACQRId}`;
    const encodeURL = this.baseUrl + operationName;
    return this.httpClient.get<any>(encodeURL).pipe(
      map((res: any) => {
        return new OngoingItems(res);
      }),
    );
  }

  updateOCAnnualReport(reqBody: any, type: boolean): Observable<any> {
    const saveOrSubmit = type ? 'save' : 'submit';
    const options = getHttpOptionsText();
    const operationName = `/annualreport/${saveOrSubmit}`;
    const encodeURL = this.baseUrl + operationName;
    return this.httpClient.put<any>(encodeURL, reqBody, options);
  }

  updateOCAnnualReportCerti(reqBody: any, type: boolean): Observable<any> {
    const saveOrSubmit = type ? 'save' : 'submit';
    const options = getHttpOptionsText();
    const operationName = `/annualReport/certification/${saveOrSubmit}`;
    const encodeURL = this.baseUrl + operationName;
    return this.httpClient.put<any>(encodeURL, reqBody, options);
  }

  public notifyChild(data: any) {
    if (data) {
      this.notify.next(data);
    }
  }

  public notifyAddtnlCertPrint(data: any) {
    if (data) {
      this.additionalCertificatePrint.next(data);
    }
  }

  getAnnualReport(surveyId: string): Observable<any> {
    const operationName = `/survey/annualreport/${surveyId}`;
    const encodeURL = this.baseUrl + operationName;
    return this.httpClient.get<any>(encodeURL).pipe(
      map((res: any) => {
        return res;
      }),
    );
  }

  getAnnualPaymentDetails(acqrId: string, surveyId: string): Observable<any> {
    const operationName = `/payment/acqr/${acqrId}/survey/${surveyId}`;
    const encodeURL = this.baseUrl + operationName;
    const options = getHttpOptionsText();
    const reqBody = '';
    return this.httpClient.put<any>(encodeURL, reqBody, options).pipe(
      map((res: any) => {
        return new AnnualMaintenanceFee(res);
      }),
    );
  }

  getAdditionalCertificatePaymentsData(
    surveyId: string,
    quantitySmall: number,
    quantityLarge: number,
  ): Observable<AdditionalCertificatePayment> {
    const operationName = `/payment/certificates/${surveyId}/small/${quantitySmall}/large/${quantityLarge}`;
    const encodeURL = this.baseUrl + operationName;
    return this.httpClient.get<any>(encodeURL).pipe(
      map((res: any) => {
        return new AdditionalCertificatePayment(res);
      }),
    );
  }

  createAdditionalCertificateOrder(item: PostAdditionalCertificatePayment): Observable<any> {
    const options = getHttpOptionsText();
    const operationName = '/additionalcertificates';
    const encodeURL = this.baseUrl + operationName;
    return this.httpClient.post<any>(encodeURL, item, options).pipe(
      map((res: any) => {
        return JSON.parse(res);
      }),
    );
  }

  public notifyPrintAction(res: any) {
    if (res) {
      this.notifyPrint.next(res);
    }
  }
  getItemsCheckList(surveyId: string): Observable<ItemsChecklist> {
    const operationName = `/survey/itemschecklist/${surveyId}`;
    const encodeURL = this.baseUrl + operationName;
    return this.httpClient.get<any>(encodeURL).pipe(
      map((res: ItemsChecklist) => {
        return new ItemsChecklist(res);
      }),
    );
  }

  uploadQip(file: File, surveyId): Observable<object> {
    const options = getHttpOptionsText();
    const formData: FormData = new FormData();
    formData.append('file', file);
    options.headers = new HttpHeaders().append('Content-Disposition', 'multipart/form-data');
    const operationName = `/qip/${surveyId}`;
    const encodeURL = this.baseUrl + operationName;
    return this.httpClient.put(encodeURL, formData, options).pipe(map((response) => response));
  }

  sendAppInvoiceReport(emailData): Observable<any> {
    const operationName = '/servertask/acqrinvoice';
    const options = getHttpOptionsText();
    const encodeURL = this.baseUrl + operationName;
    const reqBody = emailData;
    return this.httpClient.post<any>(encodeURL, reqBody, options);
  }
}
